<template>
  <div class="pd-20">
    <a-page-header class="pd-0" style="padding">
      <template #title>
        <h1>
          <img
            class="mr-5"
            src="@/assets/images/dashboard/contracts/livelo-color.png"
            alt="img"
            width="30"
          />
          Livelo
        </h1>
      </template>

      <template #extra>
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default iblock"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="contract.list"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
        >
          <img
            class="c-pointer ml-10"
            src="@/assets/images/dashboard/excel.png"
            alt="excel"
          />
        </downloadExcel>
        <aButton
          v-if="activeTab === 'Pendente'"
          :disabled="selectedRowKeys.length === 0 ? true : false"
          class="ml-10"
          type="primary"
          ghost
          @click="viewLiveloBulkEditModal = true"
        >
          Enviar pontos Livelo
        </aButton>
        <aButton
          class="ml-20"
          type="primary"
          @click="openLiveloPointsRule = true"
        >
          Regra de pontuação Livelo
        </aButton>
      </template>
    </a-page-header>

    <a-tabs type="card" :default-active-key="activeTab" @change="onChangeTabs">
      <a-tab-pane key="Pendente" tab="PENDENTES" force-render> </a-tab-pane>
      <a-tab-pane key="Finalizado" tab="FINALIZADOS" force-render> </a-tab-pane>
    </a-tabs>

    <aRow v-if="contract.meta.total_sold" class="haya-panels" :gutter="20">
      <aCol :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            R$ {{ contract.meta ? contract.meta.total_sold : 0 }}
          </div>
          <div class="txt">Valor total dos contratos</div>
          <div class="footer">
            N° de contratos: {{ contract.meta ? contract.meta.total : 0 }}
          </div>
        </div>
      </aCol>

      <aCol :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contract.meta ? contract.meta.marketing.livelo.totalPoints : 0 }}
          </div>
          <div class="txt">Pontos Disponíveis</div>

          <div class="footer">
            Valor custo: R$
            {{ contract.meta ? contract.meta.marketing.livelo.profit : 0 }}
          </div>
        </div>
      </aCol>

      <aCol :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{
              contract.meta ? contract.meta.marketing.livelo.totalPointsSent : 0
            }}
          </div>
          <div class="txt">Pontos Utitilizados</div>
          <div class="footer">
            Valor custo: R$
            {{ contract.meta ? contract.meta.marketing.livelo.profitSent : 0 }}
          </div>
        </div>
      </aCol>

      <aCol :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            R$ {{ contract.meta ? contract.meta.total_profit : 0 }}
          </div>
          <div class="txt">Lucratividade total dos contratos</div>
          <div class="footer">
            {{ contract.meta ? contract.meta.percentage_profit : 0 }}% de lucro
            em relação às vendas
          </div>
        </div>
      </aCol>
    </aRow>

    <aCollapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <aCollapsePanel key="1">
        <template #header>
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <aRow class="fiter-collumns" :gutter="20">
          <aCol :span="3">
            <div class="travel-input">
              <label for class="filled">ID do contrato</label>
              <a-input
                placeholder="ID + Enter"
                v-model="contract.filters.id"
                @pressEnter="getContracts()"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Contratante </label>
              <a-auto-complete
                class="auto-complete"
                :data-source="
                  customers.list.map(({ first_name, last_name, id }) => ({
                    value: id,
                    text: `${id} - ${first_name} ${last_name}`,
                  }))
                "
                v-model="customers.filters.searchTerm"
                placeholder="Buscar contratantes..."
                @change="
                  customers.filters.searchTerm ? getCustomers() : getContracts()
                "
                @select="customerSelected"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Check-in</label>
              <a-range-picker
                v-model="contract.filters.checkin.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Check-out</label>
              <a-range-picker
                v-model="contract.filters.checkout.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </aCol>

          <aCol :span="3">
            <div class="travel-input">
              <label for class="filled">Regra</label>
              <a-input
                placeholder="Ex: 10"
                v-model="contract.filters.livelo.rule"
                @blur="getContracts()"
                class="rule-field"
              />
              <span class="f12 cprimary">pts = 1 R$</span>
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label for class="filled">Range pontos</label>
              <a-input-group compact>
                <a-input
                  type="number"
                  class="a-center"
                  style="width: 45%; border-radius: 0"
                  placeholder="Min."
                  v-model="contract.filters.livelo.pointsRange.min"
                  @blur="getContracts()"
                />
                <a-input
                  style="
                    width: 10%;
                    border-left: 0;
                    pointer-events: none;
                    background-color: #fff;
                    border: 0;
                  "
                  placeholder="até"
                />
                <a-input
                  type="number"
                  style="
                    width: 45%;
                    text-align: center;
                    border-left: 0;
                    border-radius: 0;
                  "
                  placeholder="Max"
                  v-model="contract.filters.livelo.pointsRange.max"
                  @blur="getContracts()"
                />
              </a-input-group>
            </div>
          </aCol>

          <aCol :span="6">
            <a-checkbox
              v-model="contract.filters.livelo.availableToSend"
              style="font-size: 13px; font-weight: 500; position: relative"
              @change="
                getContracts();
                selectedRowKeys = [];
              "
            >
              Liberado para envio
            </a-checkbox>
          </aCol>
        </aRow>
      </aCollapsePanel>
    </aCollapse>

    <aTable
      class="travel-table"
      :columns="liveloColumns"
      :data-source="contract.list"
      :loading="contract.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      :row-selection="activeTab === 'Pendente' ? rowSelectionCondition : null"
      @change="contractTableChange"
    >
      <div slot="id" slot-scope="text">{{ text }}</div>

      <div slot="alert_transaction_data" slot-scope="record">
        <a-tooltip
          v-if="!record.meta.livelo_transaction_date"
          placement="right"
        >
          <template #title>
            Atenção, a DATA DA TRANSAÇÃO DA LIVELO <b>não</b> foi
            selecionada,<br />
            edite o contrato, e vá na seção marketing para selecionar a data.
          </template>
          <a-icon class="red c-pointer f16" type="warning" />
        </a-tooltip>
      </div>

      <div slot="contractor" slot-scope="record">
        <div class="dotted-phrase upper">
          {{ record.customer.first_name }}
          {{ record.customer.last_name }}
        </div>
      </div>

      <template slot="product" slot-scope="record">
        <a-tooltip
          class="travel-tooltip"
          placement="top"
          style="display: inline-block"
        >
          <template #title>
            <div v-html="formatProductsTxt(record.meta)" />
          </template>
          <a-tag
            v-for="(product, index) in formatProductsList(
              record.contracted_services
            )"
            :key="index"
            :color="product.color"
          >
            <div v-html="product.name" />
          </a-tag>
        </a-tooltip>
      </template>

      <div slot="points_value" slot-scope="record">
        {{
          record.meta.livelo_points_total_fee
            ? formatFloatPricePtBr(record.meta.livelo_points_total_fee)
            : "---"
        }}
      </div>

      <div slot="company" slot-scope="record">
        {{
          record.company_branch.name
            ? record.company_branch.name
            : record.company
        }}
      </div>

      <div slot="user" slot-scope="record">
        <aTooltip>
          <template #title>
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </aTooltip>
      </div>

      <template slot="created" slot-scope="record">
        {{ formatDateTime(record.created) }}
      </template>

      <template slot="livelo_amount" slot-scope="record">
        {{
          record.meta.livelo_amount_to_receive
            ? formatInt(record.meta.livelo_amount_to_receive)
            : "---"
        }}
      </template>

      <template slot="checkin" slot-scope="record">
        {{
          record.meta.first_checkin
            ? formatMultiDates(record.first_checkin)
            : "---"
        }}
      </template>

      <template slot="checkout" slot-scope="record">
        {{
          record.last_checkout ? formatMultiDates(record.last_checkout) : "---"
        }}
      </template>

      <template slot="livelo_profit" slot-scope="record">
        <div v-html="contractProfit(record)" />
      </template>

      <template slot="livelo_points_rule" slot-scope="record">
        {{
          record.meta.livelo_points_rule
            ? `R$ 1 = ${record.meta.livelo_points_rule} pts`
            : "---"
        }}
      </template>

      <template slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Editar">
          <a class="edit" @click="edit(record.id)">
            <a-icon type="edit-svg" />
          </a>
        </a-tooltip>

        <a-tooltip placement="top" title="Resumo">
          <a
            class="edit ml-10"
            @click="viewContractDetails(record.id)"
            style="position: relative; top: -4px"
          >
            <img
              src="@/assets/images/dashboard/contracts/details-ico.png"
              width="19"
              alt="details"
            />
          </a>
        </a-tooltip>
      </template>
    </aTable>

    <div class="a-center mt-10" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="contract.pagination.page"
        :page-size-options="pageSizeOptions"
        :page-size.sync="contract.pagination.perPage"
        :total="contract.pagination.total"
        @change="changeContractsPage"
        @showSizeChange="changeContractsPageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value != '9999'">{{ props.value }} / página</span>
          <span v-if="props.value == '9999'">Todos</span>
        </template>
      </a-pagination>
    </div>

    <aModal
      v-model="viewLiveloBulkEditModal"
      :width="$root.windowWidth > 960 ? '800px' : '100%'"
      title="Livelo - Envio em Massa"
      :footer="false"
      @cancel="closeLiveloBulkEditModal(false)"
    >
      <LiveloBulkEditModal
        v-if="viewLiveloBulkEditModal"
        :liveloProductsArr="liveloProductsArr"
      />
    </aModal>

    <aModal
      class="contract-details pd-0"
      v-model="openContractDetailsModal"
      :title="false"
      :footer="false"
      width="884px"
      @cancel="openContractDetailsModal = false"
    >
      <ContractResume
        v-if="openContractDetailsModal"
        :contractId="contractId"
        @updateContractsList="updateContractsList"
      />
    </aModal>

    <aDrawer
      :title="false"
      placement="right"
      width="1100px"
      :closable="true"
      :visible="openLiveloPointsRule"
      @close="openLiveloPointsRule = false"
    >
      <LiveloPointsRuleModal v-if="openLiveloPointsRule" />
    </aDrawer>
  </div>
</template>

<script>
import { subHours, format } from "date-fns";
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins";

import ContractResume from "@/components/contracts/modals/ContractModal.vue";
import customerMixins from "@/mixins/customers/customerMixins";
import LiveloBulkEditModal from "@/components/livelo/modals/LiveloBulkEditModal.vue";
import LiveloPointsRuleModal from "@/components/livelo/modals/LiveloPointsRuleModal.vue";

let transactionDateUnformatted = subHours(new Date(), 3);

export default {
  name: "ContractLiveloListV2",
  components: { LiveloPointsRuleModal, ContractResume, LiveloBulkEditModal },
  mixins: [contractMixins, formatThings, customerMixins],
  data() {
    return {
      openLiveloPointsRule: false,
      viewLiveloBulkEditModal: false,
      openContractDetailsModal: false,
      pageSizeOptions: ["10", "20", "30", "50", "100", "500", "9999"],
      selectedRowKeys: [],
      activeTab: "Pendente",
      liveloProductsArr: [],
      transationDate: transactionDateUnformatted,
      contractId: undefined,
      excelFile: {
        header: "Livelo",
        fileName: "Livelo.xls",
        collumns: {
          ID: "id",
          Contrante: {
            field: "customer",
            callback: (customer) => {
              return `${customer.first_name} ${customer.last_name}`;
            },
          },
          Produtos: {
            field: "meta",
            callback: (meta) => {
              return `${this.formatProductsTxt(meta)}`;
            },
          },
          Regra: {
            field: "meta",
            callback: (meta) => {
              return `${
                meta.livelo_points_rule
                  ? `R$ 1 = ${meta.livelo_points_rule} pts`
                  : "---"
              }`;
            },
          },
          Pontos: {
            field: "meta",
            callback: (meta) => {
              return `${
                meta.livelo_amount_to_receive
                  ? this.formatInt(meta.livelo_amount_to_receive)
                  : "---"
              }`;
            },
          },
          "Valor dos Pontos": {
            field: "meta",
            callback: (meta) => {
              return `${
                meta.livelo_points_total_fee
                  ? this.formatFloatPricePtBr(meta.livelo_points_total_fee)
                  : "---"
              }`;
            },
          },
          Lucratividade: {
            field: "meta",
            callback: (meta) => {
              return `${
                meta.contract_profit_value
                  ? this.formatFloatPricePtBr(meta.contract_profit_value)
                  : 0
              }`;
            },
          },
          "Empresa/Filial": {
            field: "company_branch",
            callback: (company_branch) => {
              return `${company_branch.name}`;
            },
          },
          Usuário: {
            field: "user",
            callback: (user) => {
              return `${user.first_name} ${user.last_name}`;
            },
          },
          "Data/Hora": "created",
        },
        data: [],
        footer: [],
      },
      liveloColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "",
          scopedSlots: { customRender: "alert_transaction_data" },
          width: 60,
        },
        {
          title: "Contratante",
          scopedSlots: { customRender: "contractor" },
          width: 180,
        },
        {
          title: "Produtos",
          scopedSlots: { customRender: "product" },
          width: 160,
        },
        {
          title: "Check-in",
          scopedSlots: { customRender: "checkin" },
          width: 120,
          sorter: true,
          key: "first_checkin2",
        },
        {
          title: "Check-out",
          scopedSlots: { customRender: "checkout" },
          width: 120,
          sorter: true,
          key: "last_checkout2",
        },
        {
          title: "Regra",
          scopedSlots: { customRender: "livelo_points_rule" },
          width: 120,
        },

        {
          title: "Pontos",
          scopedSlots: { customRender: "livelo_amount" },
          width: 90,
          //sorter: true,
          key: "livelo_amount_to_receive",
        },
        {
          title: "Valor dos pontos",
          scopedSlots: { customRender: "points_value" },
          width: 110,
        },

        {
          title: "Lucratividade",
          scopedSlots: { customRender: "livelo_profit" },
          width: 110,
        },
        {
          title: "Empresa/Filial",
          scopedSlots: { customRender: "company" },
          key: "company",
          width: 140,
        },
        {
          title: "Data/Hora",
          scopedSlots: { customRender: "created" },
          key: "created",
          width: 150,
          sorter: true,
        },
        {
          title: "",
          scopedSlots: { customRender: "user" },
          width: 60,
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 100,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    document.title = `Livelo - HAYA`;

    this.contract.filters.status.selected = "concluded";
    this.contract.pagination.perPage = 30;
    this.contract.filters.marketing.selected = "Livelo";
    this.contract.filters.orderBy = "created";
    this.contract.filters.order = "desc";
    this.contract.filters.liveloPointsSendingStatus.selected = "Pendente";

    this.contract.filters.systemRoute = "livelo";

    this.contractUrlDefaults();
    this.getContracts();
  },
  computed: {
    rowSelectionCondition() {
      const { selectedRowKeys } = this;

      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: this.disableLiveloUntilLastCheckout(record),
          },
        }),
        hideDefaultSelections: true,
      };
    },
  },
  methods: {
    disableLiveloUntilLastCheckout(contract) {
      let disableButton = true,
        todayFormat = format(new Date(), "yyyy-MM-dd"),
        today = new Date(`${todayFormat} 00:00`).getTime(),
        lastCheckout = "";

      if (["", undefined].includes(contract.meta.livelo_transaction_date))
        return disableButton;

      if (contract.meta.contract_last_checkout != undefined) {
        lastCheckout = contract.meta.contract_last_checkout;

        if (contract.meta.contract_last_checkout.includes("T")) {
          lastCheckout = lastCheckout.split("T")[0];
          lastCheckout = new Date(lastCheckout).getTime();
        } else {
          lastCheckout = new Date(lastCheckout).getTime();
        }
      }

      if (today > lastCheckout) {
        if (
          contract.meta.livelo_points_rule != undefined &&
          contract.meta.livelo_points_rule != "" &&
          contract.meta.livelo_amount != undefined &&
          contract.meta.livelo_amount != "" &&
          contract.meta.livelo_transaction_date != ""
        ) {
          disableButton = false;
        }
      }

      return disableButton;
    },
    customerSelected(customer) {
      this.customers.filters.searchTerm = customer;
      this.contract.filters.customer = customer;

      this.getContracts();
    },
    updateContractsList() {
      this.openContractDetailsModal = false;
      this.getContracts(this.pagination.page);
    },
    viewContractDetails(id) {
      this.contractId = id;
      this.openContractDetailsModal = true;
    },
    closeLiveloBulkEditModal() {
      if (this.loadingLiveloBulkRequest) {
        this.viewLiveloBulkEditModal = false;
      } else {
        this.viewLiveloBulkEditModal = true;
      }

      this.loadingLiveloBulkRequest = false;

      this.getContracts();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.liveloProductsArr = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
    },
    onChangeTabs(tab) {
      this.activeTab = tab;
      this.contract.filters.liveloPointsSendingStatus.selected = tab;
      this.getContracts();

      this.selectedRowKeys = [];
    },

    contractProfit(contract) {
      let html = "",
        percentage = 0,
        profit = contract.meta.contract_profit_value
          ? this.formatFloatPricePtBr(contract.meta.contract_profit_value)
          : 0;

      percentage = contract.meta.contract_profit_percentage_value
        ? parseFloat(contract.meta.contract_profit_percentage_value)
        : parseFloat(contract.meta.sale_profit_percentage_value);

      if (percentage > 11) {
        html = `<div class="cgreen f11">${profit}</div>`;
      }

      if (percentage <= 11 && percentage >= 0) {
        html = `<div class=" f11">${profit}</div>`;
      }

      if (percentage < 0) {
        html = `<div class="red f11">${profit}</div>`;
      }

      return html;
    },
    edit(id) {
      this.$router.push(`/contracts/edit/${id}`);
    },
    formatProductsTxt(meta) {
      let txt = "";

      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          p;
          // HOTEL
          if (p == "hotel") {
            JSON.parse(meta.hotel_rows).forEach((row) => {
              if (meta[`hotel_${row.id}_name`] != undefined) {
                txt += meta[`hotel_${row.id}_name`]
                  ? meta[`hotel_${row.id}_name`] + "<br>"
                  : `HOTEL ${row.id}, `;
              }
            });
          }

          //AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                if (index == 0) {
                  txt += `AÉREO - ${meta[`flight_${row.id}_class`]} - SAÍDA: ${
                    meta[`flight_${row.id}_origin`]
                  } <br>`;
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    txt += `AÉREO ${flight.id}  ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈ ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br> `;
                  }

                  if (
                    index == flight.sections.length - 1 &&
                    flight.sections.length > 1
                  ) {
                    txt += `AÉREO ${flight.id} ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈  ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br>`;
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            if (meta.service_rows != undefined) {
              JSON.parse(meta.service_rows).forEach((row) => {
                if (meta[`service_${row.id}_name`] != undefined) {
                  txt += meta[`service_${row.id}_name`];
                }
              });
            }
          }
        });
      }

      return txt.toUpperCase();
    },
    generateContractReportsData() {
      // don't remove
    },
  },
};
</script>

<style lang="sass">
.contract-details
  .ant-modal-close
    top: -48px
    padding: 0 !important
    height: 20px
    width: 20px
    color: #FFF !important
    right: 16px
  .ant-modal-body
    padding: 0
</style>

<style lang="sass" scoped>
.rule-field
  width: 50px
.auto-complete
  width: 100%
  height: 32px
</style>
